import Axios from "axios";

import { apiUrl } from "../../constants/config";

Axios.interceptors.request.use((config) => {
  // Apply auth token to each request (if any).
  try {
    const hash = window?.location?.pathname?.match(/^\/r\/([^/]*)/)[1];
    const adminTokens = JSON.parse(localStorage.getItem("adminTokens"));
    const token = adminTokens[hash];
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  } catch {
    // Above code failed but we can stil perform the request.
  }
  return config;
});

Axios.defaults.baseURL = apiUrl;
