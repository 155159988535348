import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import Logo from "../../../images/logo.png";
import styles from "./footer.module.css";
import Languages from "./Languages/Languages";

export default function Footer() {
  const { t } = useTranslation("components.mainLayout");
  const location = useLocation();

  const isStartPage = location?.pathname === "/";

  return (
    <div className={styles.container}>
      <img className={styles.logo} src={Logo} />
      <div className={styles.content}>
        {!isStartPage && (
          <>
            <Link className={styles.createLink} to="/">
              {t("create")}
            </Link>
            <div className={styles.separator} />
          </>
        )}
        <Languages />
      </div>
    </div>
  );
}
