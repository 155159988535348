import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  IoCheckmarkCircleOutline,
  IoCopyOutline,
  IoShareSocialSharp,
} from "react-icons/io5";

import { shareLink } from "../../../../constants/config";
import { Button } from "../../../shared";

export default function ShareLink({ hash }) {
  const { t } = useTranslation("components.receipt", { keyPrefix: "share" });
  const [copied, setCopied] = useState(false);

  const url = `${shareLink}/${hash}`;

  const onShare = () => {
    navigator.share({ url });
  };

  const onCopy = () => {
    navigator.clipboard.writeText(url);
    setCopied(true);
  };

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => setCopied(false), 2000);
      return () => clearTimeout(timeout);
    }
  }, [copied]);

  if (navigator.share) {
    return (
      <Button
        color="transparent"
        icon={IoShareSocialSharp}
        label={t("share")}
        onClick={onShare}
      />
    );
  }

  return (
    <Button
      color="transparent"
      icon={copied ? IoCheckmarkCircleOutline : IoCopyOutline}
      label={copied ? t("copied") : t("copy")}
      onClick={onCopy}
    />
  );
}
