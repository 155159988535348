import i18n from "i18next";
import moment from "moment";
import EnDates from "moment/locale/en-gb";
import SvDates from "moment/locale/sv";
import { initReactI18next } from "react-i18next";

import EnLabels from "../../languages/en.json";
import SvLabels from "../../languages/sv.json";

const labels = { en: EnLabels, sv: SvLabels };

const dates = { en: EnDates, sv: SvDates };

const languageDetector = {
  async: true,
  cacheUserLanguage: (locale) => {
    // Called on init or when user changing the language.
    moment.updateLocale(locale, dates[locale]);
    localStorage.setItem("language", locale);
  },
  detect: async (setLanguage) => {
    // Use saved language from storage if any.
    const savedLocale = localStorage.getItem("language");
    if (savedLocale) {
      return setLanguage(savedLocale);
    }
  },
  init: () => {},
  type: "languageDetector",
};

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "sv",
    react: {
      useSuspense: false,
    },
    resources: labels,
  });

export default i18n;
