import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useParams, useSearchParams } from "react-router-dom";

import styles from "./confirmPayment.module.css";

export default function ConfirmPayment() {
  const { t } = useTranslation("components.confirmPayment");
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState(false);
  const [payment, setPayment] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const swishPayment = JSON.parse(searchParams.get("swishPayment"));

      // Check if payment from Swish was successful.
      if (swishPayment?.result !== "paid") {
        setError(true);
        return null;
      }

      // If it was, update the payment status with the actual amount paid.
      try {
        const response = await axios.patch(`payments/${params?.hash}`, {
          amountPaid: swishPayment?.amount,
          confirmed: true,
        });
        setPayment(response?.data);
      } catch {
        setError(true);
      }
    };
    fetchData();
  }, [params?.hash, searchParams]);

  if (payment) {
    // If payment but on desktop then show success message.
    if (payment?.qrCode) {
      return (
        <div className={styles.container}>
          <div className={styles.title}>{t("success.title")}</div>
          <div className={styles.subtitle}>{t("success.subtitle")}</div>
        </div>
      );
    } else {
      // If payment confirmed and user is on mobile - forward to payments tab.
      return (
        <Navigate
          replace
          to={`/r/${payment?.receipt?.hash}/payments?id=${payment?.id}`}
        />
      );
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.info}>{error ? t("error") : t("confirming")}</div>
    </div>
  );
}
