import classNames from "classnames";
import { Outlet } from "react-router-dom";

import Footer from "./Footer/Footer";
import styles from "./mainLayout.module.css";

export default function MainLayout({ fullscreen }) {
  return (
    <div
      className={classNames([
        styles.container,
        fullscreen && styles.fullscreen,
      ])}
    >
      <Outlet />
      <Footer />
    </div>
  );
}
