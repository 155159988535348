import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Button, Input } from "../../shared";
import styles from "./receiptDetails.module.css";

export default function ReceiptDetails({
  currency: defaultCurrency,
  hostPhone: defaultHostPhone,
  onSuccess,
  tip: defaultTip,
}) {
  const { t } = useTranslation("components.receipt", { keyPrefix: "details" });
  const params = useParams();
  const [submitting, setSubmitting] = useState(false);
  const [hostPhone, setHostPhone] = useState(defaultHostPhone || "");
  const [tip, setTip] = useState(defaultTip || 0);
  const [currency, setCurrency] = useState(defaultCurrency || "");

  const onSubmit = async () => {
    setSubmitting(true);
    try {
      const response = await axios.patch(`receipts/${params?.hash}`, {
        currency,
        hostPhone,
        tip,
      });
      onSuccess(response?.data); // Update new receipt in "Receipt".
    } catch {
      toast.error(t("error"));
    }
    setSubmitting(false);
  };

  return (
    <div className={styles.container}>
      <Input
        className={styles.item}
        label={t("editPhone")}
        onChange={(value) => setHostPhone(value)}
        type="tel"
        value={hostPhone}
      />
      <div className={styles.row}>
        <Input
          className={styles.item}
          label={t("editTip")}
          onChange={(value) => setTip(value)}
          type="number"
          value={tip}
        />
        <Input
          className={styles.item}
          label={t("editCurrency")}
          onChange={(value) => setCurrency(value)}
          value={currency}
        />
      </div>
      <Button
        className={styles.button}
        label={t("save")}
        loading={submitting}
        onClick={onSubmit}
      />
    </div>
  );
}
