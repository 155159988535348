import { useTranslation } from "react-i18next";
import { IoArrowDownOutline } from "react-icons/io5";

import AddPhoneNumber from "../../../images/illustrations/add-phone-number.png";
import GetCash from "../../../images/illustrations/get-cash.png";
import TakePhoto from "../../../images/illustrations/take-photo.png";
import styles from "./info.module.css";

export default function Info() {
  const { t } = useTranslation("components.createReceipt", {
    keyPrefix: "info",
  });

  return (
    <div className={styles.container}>
      <div className={styles.title}>{t("title")}</div>
      <div className={styles.item}>
        <img className={styles.icon} src={TakePhoto} />
        <div>
          <div className={styles.step}>{t("step", { position: 1 })}</div>
          <div>{t("step1")}</div>
        </div>
      </div>
      <IoArrowDownOutline size={16} />
      <div className={styles.item}>
        <img className={styles.icon} src={AddPhoneNumber} />
        <div>
          <div className={styles.step}>{t("step", { position: 2 })}</div>
          <div>{t("step2")}</div>
        </div>
      </div>
      <IoArrowDownOutline size={16} />
      <div className={styles.item}>
        <img className={styles.icon} src={GetCash} />
        <div>
          <div className={styles.step}>{t("step", { position: 3 })}</div>
          <div>{t("step3")}</div>
        </div>
      </div>
    </div>
  );
}
