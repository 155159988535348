import useAxios from "axios-hooks";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoCheckmark, IoClose } from "react-icons/io5";
import QRCode from "react-qr-code";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Button, Modal } from "../../../../shared";
import styles from "./swishModal.module.css";

export default function SwishModal({ onClose, open, paymentId, value }) {
  const { t } = useTranslation("components.receipt", {
    keyPrefix: "payment.modal",
  });
  const navigate = useNavigate();
  const params = useParams();
  const [{ data }, checkPayment] = useAxios(
    `receipts/${params?.hash}/payments/${paymentId}`
  );
  const [submitting, setSubmitting] = useState(false);

  const goToPayment = useCallback(() => {
    navigate(`/r/${params?.hash}/payments?id=${paymentId}`);
  }, [navigate, params, paymentId]);

  const onCheckPayment = async () => {
    setSubmitting(true);
    const response = await checkPayment();
    if (!response?.data?.confirmed) {
      toast.error(t("error"));
    }
    setSubmitting(false);
  };

  const onModalClose = useCallback(() => {
    onClose();
    setSubmitting(false);
  }, [onClose]);

  useEffect(() => {
    if (paymentId) {
      // Check if user has paid with hos phone every 3s.
      const interval = setInterval(checkPayment, 3000);
      return () => clearInterval(interval);
    }
  }, [checkPayment, paymentId]);

  useEffect(() => {
    if (data?.confirmed) {
      goToPayment();
    }
  }, [data, goToPayment]);

  return (
    <Modal onClose={onModalClose} open={open}>
      <div className={styles.content}>
        <div className={styles.title}>{t("info")}</div>
        <QRCode value={value} />
        <div className={styles.buttons}>
          <Button
            className={styles.button}
            color="red"
            icon={IoClose}
            label={t("close")}
            onClick={onModalClose}
          />
          <Button
            className={styles.button}
            disabled={submitting}
            icon={IoCheckmark}
            label={t("iHavePaid")}
            onClick={onCheckPayment}
          />
        </div>
      </div>
    </Modal>
  );
}
