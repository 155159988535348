import classNames from "classnames";
import PropTypes from "prop-types";
import { createElement } from "react";
import ReactLoading from "react-loading";

import { black, green, red, white } from "../../../constants/colors";
import styles from "./button.module.css";

export default function Button({
  children,
  className,
  color,
  disabled,
  icon,
  label,
  loading,
  onClick,
}) {
  const availableColors = { green, red, transparent: "transparent" };
  const backgroundColor = !children ? availableColors[color] || green : null;
  const fontColor = color === "transparent" ? black : white;

  const clickable = !disabled && !loading;

  const imageOrIcon =
    typeof icon === "string" ? (
      <img alt="" className={styles.image} src={icon} />
    ) : icon ? (
      createElement(icon, {
        className: styles.icon,
        color: fontColor,
        size: 16,
      })
    ) : null;

  return (
    <div
      className={classNames({
        [className]: true,
        [styles.container]: true,
        [styles.button]: !children,
        [styles.disabled]: disabled,
        [styles.clickable]: clickable,
      })}
      onClick={clickable ? onClick : null}
      style={{ backgroundColor }}
    >
      {children ? (
        children
      ) : loading ? (
        <ReactLoading
          className={styles.loader}
          color={fontColor}
          height={16}
          type="spokes"
          width={16}
        />
      ) : (
        imageOrIcon
      )}
      <div style={{ color: fontColor }}>{label}</div>
    </div>
  );
}

Button.propTypes = {
  className: PropTypes.any,
  color: PropTypes.oneOf(["green", "red", "transparent"]),
  disabled: PropTypes.bool,
  icon: PropTypes.any,
  label: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  className: null,
  color: "green",
  disabled: false,
  icon: null,
  label: "",
  loading: false,
  onClick: null,
};
