import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { set } from "timm";

export default function useAuth(overrideHash) {
  const params = useParams();
  const hash = overrideHash || params?.hash;

  const getStore = () => {
    const store = JSON.parse(localStorage.getItem("adminTokens")) || {};
    return store;
  };

  const saveStore = (tokens) => {
    localStorage.setItem("adminTokens", JSON.stringify(tokens));
  };

  const setToken = useCallback(
    (token) => {
      const tokens = getStore();
      const updatedTokens = set(tokens, hash, token);
      saveStore(updatedTokens);
    },
    [hash]
  );

  const token = useMemo(() => {
    const adminTokens = getStore();
    return adminTokens[hash];
  }, [hash]);

  return [token, setToken];
}
