import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../../shared";
import styles from "./splitProduct.module.css";

export default function SplitProduct({ max, onSelect }) {
  const { t } = useTranslation("components.receipt", { keyPrefix: "product" });

  const items = useMemo(() => {
    const arr = [];
    for (let i = 2; i <= 10; i++) {
      arr.push({
        label: `1/${i}`,
        value: Math.round((1 / i) * 100) / 100,
      });
    }
    return arr;
  }, []);

  const onClick = (item) => {
    if (item.value <= max) {
      onSelect(item.value);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.label}>{t("split")}</div>
      <div className={styles.items}>
        {items?.map((item) => (
          <Button
            className={styles.item}
            disabled={item.value > max}
            key={item.value}
            label={item.label}
            onClick={() => onClick(item)}
          />
        ))}
      </div>
    </div>
  );
}
