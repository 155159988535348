import classNames from "classnames";
import n2f from "num2fraction";
import PropTypes from "prop-types";

import AlcoholDrink from "../../../images/icons/alcohol-drink.png";
import AlcoholShot from "../../../images/icons/alcohol-shot.png";
import Beer from "../../../images/icons/beer.png";
import Beverage from "../../../images/icons/beverage.png";
import BiffRydberg from "../../../images/icons/biff-rydberg.png";
import Burger from "../../../images/icons/burger.png";
import Cake from "../../../images/icons/cake.png";
import Champagne from "../../../images/icons/champagne.png";
import Cheese from "../../../images/icons/cheese.png";
import Chicken from "../../../images/icons/chicken.png";
import Coffee from "../../../images/icons/coffee.png";
import CremeBrulee from "../../../images/icons/creme-brulee.png";
import Fish from "../../../images/icons/fish.png";
import FishAndChips from "../../../images/icons/fish-and-chips.png";
import Food from "../../../images/icons/food.png";
import IceCream from "../../../images/icons/ice-cream.png";
import IrishCoffee from "../../../images/icons/irish-coffee.png";
import Lasagne from "../../../images/icons/lasagne.png";
import Meat from "../../../images/icons/meat.png";
import MeatStew from "../../../images/icons/meat-stew.png";
import Meatballs from "../../../images/icons/meatballs.png";
import PadThai from "../../../images/icons/pad-thai.png";
import Pancakes from "../../../images/icons/pancakes.png";
import Pasta from "../../../images/icons/pasta.png";
import Pizza from "../../../images/icons/pizza.png";
import Product from "../../../images/icons/product.png";
import RedWine from "../../../images/icons/red-wine.png";
import Sausage from "../../../images/icons/sausage.png";
import Soda from "../../../images/icons/soda.png";
import Soup from "../../../images/icons/soup.png";
import Sushi from "../../../images/icons/sushi.png";
import Tiramisu from "../../../images/icons/tiramisu.png";
import Veg from "../../../images/icons/veg.png";
import Whiskey from "../../../images/icons/whiskey.png";
import WhiteWine from "../../../images/icons/white-wine.png";
import styles from "./productIcon.module.css";

const ICONS = {
  "alcohol-drink": AlcoholDrink,
  "alcohol-shot": AlcoholShot,
  beer: Beer,
  beverage: Beverage,
  "biff-rydberg": BiffRydberg,
  burger: Burger,
  cake: Cake,
  champagne: Champagne,
  cheese: Cheese,
  chicken: Chicken,
  coffee: Coffee,
  "creme-brulee": CremeBrulee,
  fish: Fish,
  "fish-and-chips": FishAndChips,
  food: Food,
  "ice-cream": IceCream,
  "irish-coffee": IrishCoffee,
  lasagne: Lasagne,
  meat: Meat,
  "meat-stew": MeatStew,
  meatballs: Meatballs,
  "pad-thai": PadThai,
  pancakes: Pancakes,
  pasta: Pasta,
  pizza: Pizza,
  product: Product,
  "red-wine": RedWine,
  sausage: Sausage,
  soda: Soda,
  soup: Soup,
  sushi: Sushi,
  tiramisu: Tiramisu,
  veg: Veg,
  whiskey: Whiskey,
  "white-wine": WhiteWine,
};

export default function ProductIcon({ className, name, onClick, percent }) {
  return (
    <div className={classNames(styles.container, className)} onClick={onClick}>
      <img
        alt=""
        className={styles.icon}
        draggable={false}
        src={ICONS[name] || Food}
      />
      {percent < 1 && <div className={styles.percent}>{n2f(percent)}</div>}
    </div>
  );
}

ProductIcon.propTypes = {
  className: PropTypes.any,
  name: PropTypes.oneOf([
    "alcohol-drink",
    "alcohol-shot",
    "beer",
    "beverage",
    "biff-rydberg",
    "burger",
    "cake",
    "champagne",
    "cheese",
    "chicken",
    "coffee",
    "creme-brulee",
    "fish",
    "fish-and-chips",
    "food",
    "ice-cream",
    "irish-coffee",
    "lasagne",
    "meat-stew",
    "meat",
    "meatballs",
    "pad-thai",
    "pancakes",
    "pasta",
    "pizza",
    "product",
    "red-wine",
    "sausage",
    "soda",
    "soup",
    "sushi",
    "tiramisu",
    "veg",
    "whiskey",
    "white-wine",
  ]),
  onClick: PropTypes.func,
  percent: PropTypes.number,
};

ProductIcon.defaultProps = {
  className: null,
  name: "food",
  onClick: null,
  percent: 1,
};
