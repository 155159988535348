import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./input.module.css";

export default function Input({
  className,
  error,
  label,
  onChange,
  placeholder,
  type,
  value,
}) {
  return (
    <div className={classNames([styles.container, className])}>
      {label && <div className={styles.label}>{label}:</div>}
      <input
        className={classNames([styles.input, error && styles.error])}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        type={type}
        value={value}
      />
    </div>
  );
}

Input.propTypres = {
  className: PropTypes.any,
  error: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
};

Input.defaultProps = {
  className: null,
  error: false,
  label: "",
  onChange: null,
  placeholder: "",
  type: "text",
  value: "",
};
