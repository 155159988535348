import useAxios from "axios-hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { replaceAt } from "timm";

import Header from "./Header/Header";
import PayerItem from "./PayerItem/PayerItem";
import styles from "./payments.module.css";

export default function Payments() {
  const { t } = useTranslation("components.payments");
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [{ data, error, loading }] = useAxios(
    `receipts/${params?.hash}/payments`
  );
  const [items, setItems] = useState([]);

  const onAddNameSuccess = (item) => {
    const index = items?.findIndex((obj) => obj?.id === item?.id);
    if (index >= 0) {
      const updatedItems = replaceAt(items, index, item);
      setItems(updatedItems);
    }
  };

  useEffect(() => {
    setItems(data);
  }, [data]);

  if (loading || error || items?.length === 0) {
    return (
      <div className={styles.info}>
        {error ? t("error") : loading ? t("loading") : t("empty")}
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Header />
      {items?.map((item, index) => (
        <PayerItem
          amountCalculated={item?.amountCalculated}
          amountPaid={item?.amountPaid}
          currency={item?.currency}
          highlight={item?.id === +searchParams.get("id")}
          id={item?.id}
          isAdmin={item?.isAdmin}
          key={index}
          name={item?.guestName}
          onAddNameSuccess={onAddNameSuccess}
          products={item?.products}
        />
      ))}
    </div>
  );
}
