import classNames from "classnames";
import { useCallback, useMemo, useState } from "react";
import { useLongPress } from "use-long-press";

import { Modal, ProductIcon } from "../../shared";
import ProductDetails from "./ProductDetails/ProductDetails";
import styles from "./productItem.module.css";
import SplitProduct from "./SplitProduct/SplitProduct";

export default function ProductItem({
  amount,
  currency,
  icon,
  lockedAmount,
  name,
  onEdit,
  onItemPicked,
  paidAmount,
  price,
}) {
  const [splitProduct, setSplitProduct] = useState(false);

  const remainingLeft =
    Math.round((amount - paidAmount - lockedAmount) * 100) / 100;

  const icons = useMemo(() => {
    const length = remainingLeft > 10 ? 9 : Math.ceil(remainingLeft);
    if (length > 0) {
      return Array(length)
        .fill(null)
        .map((u, i) => ({
          value: Math.min(1, remainingLeft - i),
        }));
    }
    return [];
  }, [remainingLeft]);

  const onClick = useCallback(() => {
    const value = Math.min(1, remainingLeft);
    onItemPicked && onItemPicked(value);
  }, [onItemPicked, remainingLeft]);

  const onSelectSplitAmount = useCallback(
    (splitAmount) => {
      onItemPicked(splitAmount);
      setSplitProduct(false);
    },
    [onItemPicked]
  );

  const onLongPress = useCallback(() => setSplitProduct(true), []);

  const bind = useLongPress(onLongPress);

  // If user long press the icon, close the modal and long press again the hook
  // will not work but the context menu will be opened. This is a bug.
  const onContextMenu = useCallback(
    (e) => {
      e.preventDefault();
      onLongPress();
    },
    [onLongPress]
  );

  return (
    <>
      <div className={styles.separator} />
      <div
        className={classNames([
          styles.container,
          remainingLeft === 0 && styles.disabled,
        ])}
        onClick={onClick}
        onContextMenu={onContextMenu}
        {...bind()}
      >
        <ProductDetails
          amount={amount}
          currency={currency}
          name={name}
          onEdit={onEdit}
          price={price}
        />
        <div className={styles.icons}>
          {icons?.map((item, index) => (
            <ProductIcon key={index} name={icon} percent={item?.value} />
          ))}
          {remainingLeft > icons?.length && (
            <div className={styles.placeholder}>
              +{parseInt(remainingLeft - icons?.length)}
            </div>
          )}
        </div>
      </div>
      <Modal onClose={() => setSplitProduct(false)} open={splitProduct}>
        <SplitProduct max={remainingLeft} onSelect={onSelectSplitAmount} />
      </Modal>
    </>
  );
}
