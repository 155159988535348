import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { confirmPaymentUrl } from "../../../../constants/config";
import useAuth from "../../../../hooks/auth";
import useMobile from "../../../../hooks/mobile";
import SwishLogo from "../../../../images/swish.png";
import socket from "../../../../services/socket";
import { formatCurrency } from "../../../../utils/string";
import { Button } from "../../../shared";
import styles from "./swishButton.module.css";
import SwishModal from "./SwishModal/SwishModal";

export default function SwishButton({
  currency,
  currentValue,
  selectedProducts,
}) {
  const { t } = useTranslation("components.receipt", { keyPrefix: "payment" });
  const navigate = useNavigate();
  const { isMobile } = useMobile();
  const params = useParams();
  const [authToken] = useAuth();
  const [submitting, setSubmitting] = useState(false);
  const [payment, setPayment] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const swishUrl = useMemo(() => {
    const data = encodeURIComponent(
      JSON.stringify({
        amount: {
          editable: true,
          value: currentValue,
        },
        payee: {
          value: payment?.receipt?.hostPhone,
        },
        version: 1,
      })
    );
    const callback = encodeURIComponent(
      `${confirmPaymentUrl}/${payment?.hash}`
    );
    const url = `swish://payment?data=${data}&callbackurl=${callback}&callbackresultparameter=swishPayment`;
    return url;
  }, [currentValue, payment]);

  const onClick = async () => {
    setSubmitting(true);
    try {
      const products = selectedProducts?.map((item) => ({
        amount: item?.myAmount,
        id: item?.id,
      }));
      const response = await axios.post(`receipts/${params?.hash}/payments`, {
        amountCalculated: currentValue,
        products,
        qrCode: !isMobile,
        socketId: socket.id, // Connect payment to current socket.
      });
      setPayment(response?.data);
    } catch {
      toast.error(t("error"));
    }
    setSubmitting(false);
  };

  const onCloseModal = () => {
    // Reset payment when modal is closed so it won't automatically opens again
    // when/if users changes the amount in any product.
    setPayment(null);
    setOpenModal(false);
  };

  useEffect(() => {
    // When we have a payment hash, either redirect to Swish app if user is on
    // mobile, otherwise open a QR-code which user can scan with his phone.
    if (payment) {
      if (payment?.confirmed) {
        // Payment already confirmed which means admin added it.
        navigate(`/r/${params?.hash}/payments?id=${payment?.id}`);
      } else if (isMobile) {
        // Re-direct user and reset the payment with the same reason the modal
        // is closed.
        location.href = swishUrl;
        setPayment(null);
      } else {
        setOpenModal(true);
      }
    }
  }, [isMobile, navigate, params, payment, swishUrl]);

  const disabled = selectedProducts?.length === 0;

  return (
    <>
      <SwishModal
        onClose={onCloseModal}
        open={openModal}
        paymentId={payment?.id}
        value={swishUrl}
      />
      <Button
        className={styles.button}
        disabled={disabled}
        icon={authToken ? null : SwishLogo}
        label={
          authToken
            ? t("payAdmin", { amount: formatCurrency(currentValue, currency) })
            : t("paySwish", { amount: formatCurrency(currentValue, currency) })
        }
        loading={submitting}
        onClick={onClick}
      />
    </>
  );
}
