import classNames from "classnames";

import { formatCurrency } from "../../../../utils/string";
import styles from "./amountLabel.module.css";

export default function AmountLabel({
  amountCalculated,
  amountPaid,
  currency,
  isAdmin,
}) {
  const diff = amountPaid - amountCalculated;

  return (
    <>
      <span>{formatCurrency(amountPaid, currency)}</span>
      {!isAdmin && diff !== 0 ? (
        <span
          className={classNames({
            [styles.diff]: true,
            [styles.positive]: diff > 0,
            [styles.negative]: diff < 0,
          })}
        >
          {" "}
          ({formatCurrency(diff, currency)})
        </span>
      ) : null}
    </>
  );
}
