import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoAlertCircleOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Button, Input } from "../../shared";
import styles from "./productForm.module.css";
import RemoveProduct from "./RemoveProduct/RemoveProduct";

export default function ProductForm({
  item,
  onAdd,
  onDelete,
  onUpdate,
  warning,
}) {
  const { t } = useTranslation("components.receipt", {
    keyPrefix: "productForm",
  });
  const params = useParams();
  const [submitting, setSubmitting] = useState(false);
  const [name, setName] = useState(item?.name || "");
  const [price, setPrice] = useState(item?.price || 0);
  const [amount, setAmount] = useState(item?.amount || 1);

  const onSubmit = async () => {
    setSubmitting(true);
    const values = { amount, name, price };
    if (item?.id) {
      try {
        const response = await axios.patch(
          `receipts/${params?.hash}/products/${item?.id}`,
          values
        );
        onUpdate(response?.data);
      } catch {
        toast.error(t("save.error"));
      }
    } else {
      try {
        const response = await axios.post(
          `receipts/${params?.hash}/products`,
          values
        );
        onAdd(response?.data);
      } catch {
        toast.error(t("save.error"));
      }
    }
    setSubmitting(false);
  };

  return (
    <div className={styles.container}>
      {warning && (
        <div className={styles.warning}>
          <IoAlertCircleOutline className={styles.warningIcon} />
          {warning}
        </div>
      )}
      <Input
        className={styles.item}
        label={t("name")}
        onChange={(value) => setName(value)}
        value={name?.toString()}
      />
      <div className={styles.row}>
        <Input
          className={styles.item}
          label={t("price")}
          onChange={(value) => setPrice(value)}
          type="number"
          value={price?.toString()}
        />
        <Input
          className={styles.item}
          label={t("amount")}
          onChange={(value) => setAmount(value)}
          type="number"
          value={amount?.toString()}
        />
      </div>
      <div className={styles.buttons}>
        <Button label={t("save")} loading={submitting} onClick={onSubmit} />
        {item?.id && <RemoveProduct id={item?.id} onSuccess={onDelete} />}
      </div>
    </div>
  );
}
