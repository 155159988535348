import { Outlet } from "react-router-dom";

import Menu from "./Menu/Menu";
import styles from "./receiptLayout.module.css";

export default function ReceiptLayout() {
  return (
    <div className={styles.container}>
      <Menu />
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
}
