import axios from "axios";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoSwapHorizontalOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Button } from "../../../shared";

export default function SwapPriceAmount({ onSuccess }) {
  const params = useParams();
  const { t } = useTranslation("components.receipt", {
    keyPrefix: "admin.swap",
  });
  const [submitting, setSubmitting] = useState(false);

  const onClick = useCallback(async () => {
    try {
      setSubmitting(true);
      const response = await axios.put(`receipts/${params?.hash}/tools`, {
        swapPriceAmount: true,
      });
      onSuccess(response?.data?.products);
    } catch (err) {
      console.error("Could not wap price and amount.", err);
      toast.error(t("error"));
    } finally {
      setSubmitting(false);
    }
  }, [onSuccess, params?.hash, t]);

  return (
    <Button
      color="transparent"
      icon={IoSwapHorizontalOutline}
      label={t("label")}
      loading={submitting}
      onClick={onClick}
    />
  );
}
