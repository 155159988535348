import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { IoPersonCircleOutline } from "react-icons/io5";

import { formatQuantity } from "../../../utils/string";
import AddName from "./AddName/AddName";
import AmountLabel from "./AmountLabel/AmountLabel";
import styles from "./payerItem.module.css";

export default function PayerItem({
  amountCalculated,
  amountPaid,
  currency,
  highlight,
  id,
  isAdmin,
  name,
  onAddNameSuccess,
  products,
}) {
  const { t } = useTranslation("components.payments");
  const formatName = (name) => name || t("unknown");

  return (
    <div
      className={classNames([styles.container, highlight && styles.highlight])}
    >
      <div className={styles.name}>
        {name && isAdmin ? (
          <span
            className={styles.tooltipWrapper}
            data-tooltip-content={t("admin")}
            data-tooltip-id="my-tooltip"
          >
            {name}
            <IoPersonCircleOutline className={styles.icon} />
          </span>
        ) : name ? (
          name
        ) : highlight ? (
          <AddName id={id} onSuccess={onAddNameSuccess} />
        ) : (
          t("unknown")
        )}
      </div>
      <div className={styles.sum}>
        <AmountLabel
          amountCalculated={amountCalculated}
          amountPaid={amountPaid}
          currency={currency}
          isAdmin={isAdmin}
        />
      </div>
      <div className={styles.products}>
        {products
          ?.map(
            (item) =>
              `${formatName(item?.name)} (${formatQuantity(item?.amount)})`
          )
          .join(", ")}
      </div>
    </div>
  );
}
