import PropTypes from "prop-types";
import { useEffect } from "react";
import ReactModal from "react-modal";

import styles from "./modal.module.css";

export default function Modal({ children, onClose, open }) {
  useEffect(() => {
    // Disable scrolling in background when fullscreen menu is opened.
    document.body.style.overflow = open ? "hidden" : null;
    return () => (document.body.style.overflow = null);
  }, [open]);

  return (
    <ReactModal
      className={styles.container}
      isOpen={open}
      onRequestClose={onClose}
      overlayClassName={styles.overlay}
    >
      {children}
    </ReactModal>
  );
}

Modal.propTypes = {
  children: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

Modal.defaultProps = {
  children: null,
  onClose: null,
  open: false,
};
