import { useCallback, useMemo } from "react";

import useMobile from "../../../../hooks/mobile";
import { ProductIcon } from "../../../shared";
import styles from "./selectedProducts.module.css";

export default function SelectedProducts({ items, onItemRemoved }) {
  const { isMobile } = useMobile();

  const buttons = useMemo(() => {
    // All selected products are wrapped in their parent, so flatten them.
    return items?.reduce((arr, item) => {
      const length = Math.ceil(item?.myAmount);
      if (length > 0) {
        const newArr = Array(length)
          .fill(null)
          .map((u, i) => ({
            ...item,
            percent: Math.min(1, item?.myAmount - i),
          }));
        arr.push(...newArr);
      }
      return arr;
    }, []);
  }, [items]);

  const onClick = useCallback(
    (item) => {
      onItemRemoved(item, item?.percent);
    },
    [onItemRemoved]
  );

  return (
    <div className={styles.container}>
      {buttons.map((item, index) => (
        <div
          data-tooltip-content={!isMobile ? item?.name : null}
          data-tooltip-id="my-tooltip"
          key={index}
        >
          <ProductIcon
            label={item?.name}
            name={item?.icon}
            onClick={() => onClick(item)}
            percent={item?.percent}
          />
        </div>
      ))}
    </div>
  );
}
