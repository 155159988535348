import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";

import styles from "./menu.module.css";

export default function Menu() {
  const { t } = useTranslation("components.receiptLayout");
  const params = useParams();

  const items = [
    {
      label: t("components.receipt"),
      link: `/r/${params?.hash}`,
    },
    {
      label: t("components.payments"),
      link: `/r/${params?.hash}/payments`,
    },
  ];

  return (
    <div className={styles.container}>
      {items?.map((item) => (
        <NavLink
          className={({ isActive }) =>
            classNames(styles.item, isActive && styles.selected)
          }
          end
          key={item?.link}
          to={item?.link}
        >
          {item?.label}
        </NavLink>
      ))}
    </div>
  );
}
