import classNames from "classnames";
import {
  createElement,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  IoAlertCircleOutline,
  IoCheckmarkCircleOutline,
  IoInformationCircleOutline,
} from "react-icons/io5";

import { green, red } from "../../../constants/colors";
import useAuth from "../../../hooks/auth";
import styles from "./payment.module.css";
import SelectedProducts from "./SelectedProducts/SelectedProducts";
import SwishButton from "./SwishButton/SwishButton";

function Payment(
  { currency, hostPhone, isPaid, onItemRemoved, selectedProducts, tip, total },
  ref
) {
  const { t } = useTranslation("components.receipt", { keyPrefix: "payment" });
  const [authToken] = useAuth();
  const [highlight, setHighlight] = useState(false);

  const currentValue = useMemo(() => {
    const sum = selectedProducts?.reduce(
      (sum, item) => sum + (item?.myAmount || 0) * item?.price,
      0
    );
    return Math.round(sum * (tip / total + 1));
  }, [selectedProducts, tip, total]);

  const message = useMemo(() => {
    if (!hostPhone) {
      if (authToken) {
        return {
          color: red,
          icon: IoAlertCircleOutline,
          text: t("addPhone"),
        };
      }
      return {
        icon: IoAlertCircleOutline,
        text: t("noPhone"),
      };
    } else if (isPaid) {
      return {
        color: green,
        icon: IoCheckmarkCircleOutline,
        text: t("paid"),
      };
    } else if (selectedProducts?.length === 0) {
      return {
        icon: IoInformationCircleOutline,
        text: t("info"),
      };
    }
    return null;
  }, [authToken, hostPhone, isPaid, selectedProducts, t]);

  useImperativeHandle(ref, () => ({
    highlight: () => setHighlight(true),
  }));

  if (message) {
    const component = createElement(message?.icon, {
      className: styles.icon,
      color: message?.color,
    });
    return (
      <div
        className={classNames(styles.info, highlight && styles.highlight)}
        onAnimationEnd={() => setHighlight(false)}
      >
        {component}
        <div style={{ color: message?.color }}>{message?.text}</div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <SelectedProducts
        items={selectedProducts}
        onItemRemoved={onItemRemoved}
      />
      <SwishButton
        currency={currency}
        currentValue={currentValue}
        selectedProducts={selectedProducts}
      />
    </div>
  );
}

export default forwardRef(Payment);
