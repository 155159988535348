import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import useAuth from "../../../hooks/auth";
import { formatCurrency } from "../../../utils/string";
import { Button } from "../../shared";
import Summary from "./Summary/Summary";
import styles from "./toolbar.module.css";

export default function Toolbar({ currency, items, onOpenDetails, tip }) {
  const { t } = useTranslation("components.receipt", { keyPrefix: "toolbar" });
  const [authToken] = useAuth();

  const stats = items?.reduce(
    (sum, item) => ({
      locked: sum.locked + item.lockedAmount * item.price,
      paid: sum.paid + item.paidAmount * item.price,
      total: sum.total + item.amount * item.price,
    }),
    { locked: 0, paid: 0, total: 0 }
  );

  const renderTotal = useCallback(() => {
    return (
      <div>
        {t("total", { amount: formatCurrency(stats?.total + tip, currency) })}
        <div className={styles.tip}>
          {t("tip", { amount: formatCurrency(tip, currency) })}
        </div>
      </div>
    );
  }, [currency, stats?.total, t, tip]);

  return (
    <div className={styles.container}>
      <div className={styles.total}>
        {authToken ? (
          <Button onClick={onOpenDetails}>{renderTotal()}</Button>
        ) : (
          renderTotal()
        )}
      </div>
      <Summary locked={stats?.locked} paid={stats?.paid} total={stats?.total} />
    </div>
  );
}
