import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoCameraOutline, IoCloudUploadOutline } from "react-icons/io5";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";

import useAuth from "../../hooks/auth";
import styles from "./createReceipt.module.css";
import Info from "./Info/Info";
import ProgressBar from "./ProgressBar/ProgressBar";
import UploadButton from "./UploadButton/UploadButton";

export function CreateReceipt() {
  const { t } = useTranslation("components.createReceipt");
  const [file, setFile] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [result, setResult] = useState(null);
  const [, setAuthToken] = useAuth(result?.data?.hash);

  useEffect(() => {
    if (file) {
      const uploadFile = async () => {
        setSubmitting(true);
        try {
          const formData = new FormData();
          formData.append("image", file);
          // Note it return full response so we can access the headers.
          const response = await axios.post("receipts", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });
          setResult(response);
        } catch {
          toast.error(t("error"));
        }
        setSubmitting(false);
      };
      uploadFile();
    }
  }, [file, t]);

  useEffect(() => {
    if (result) {
      const token = result?.headers["authorization"];
      const extractedToken = token?.replace("Bearer ", "");
      setAuthToken(extractedToken);
    }
  }, [result, setAuthToken]);

  if (result) {
    return <Navigate to={`/r/${result?.data?.hash}`} />;
  }

  return (
    <div className={styles.container}>
      {submitting ? (
        <ProgressBar />
      ) : (
        <>
          <div className={styles.title}>{t("title")}</div>
          <div className={styles.subtitle}>
            {t("subtitle.first")}
            <br />
            {t("subtitle.second")}
          </div>
          <div className={styles.buttons}>
            <UploadButton
              capture="camera"
              icon={IoCameraOutline}
              label={t("submit.camera")}
              onUpload={setFile}
            />
            <UploadButton
              icon={IoCloudUploadOutline}
              label={t("submit.file")}
              onUpload={setFile}
            />
          </div>
          <Info />
        </>
      )}
    </div>
  );
}
