import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  IoAddCircleOutline,
  IoCallOutline,
  IoQrCodeOutline,
  IoSettingsOutline,
} from "react-icons/io5";
import QRCode from "react-qr-code";

import { shareLink } from "../../../constants/config";
import { Button, Modal } from "../../shared";
import styles from "./adminToolbar.module.css";
import ShareLink from "./ShareLink/ShareLink";
import SwapPriceAmount from "./SwapPriceAmount/SwapPriceAmount";

export default function AdminToolbar({
  hash,
  hostPhone,
  onAddProduct,
  onOpenDetails,
  onSwapPriceAmount,
}) {
  const { t } = useTranslation("components.receipt", { keyPrefix: "admin" });
  const [showQrCode, setShowQrCode] = useState(false);

  return (
    <div className={styles.container}>
      <Button
        color="transparent"
        icon={IoAddCircleOutline}
        label={t("addProduct")}
        onClick={onAddProduct}
      />
      {hostPhone ? (
        <Button
          color="transparent"
          icon={IoSettingsOutline}
          label={t("editDetails")}
          onClick={onOpenDetails}
        />
      ) : (
        <Button
          color="transparent"
          icon={IoCallOutline}
          label={t("addPhone")}
          onClick={onOpenDetails}
        />
      )}
      <SwapPriceAmount onSuccess={onSwapPriceAmount} />
      <ShareLink hash={hash} />
      <Button
        color="transparent"
        icon={IoQrCodeOutline}
        label={t("showQR")}
        onClick={() => setShowQrCode(true)}
      />
      <Modal onClose={() => setShowQrCode(false)} open={showQrCode}>
        <QRCode className={styles.qrCode} value={`${shareLink}/${hash}`} />
        <div className={styles.qrCodeInfo}>{t("qrCodeInfo")}</div>
      </Modal>
    </div>
  );
}
