import useAxios from "axios-hooks";
import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { removeAt, set, updateIn } from "timm";

import useAuth from "../../hooks/auth";
import socket from "../../services/socket";
import { Modal } from "../shared";
import AdminToolbar from "./AdminToolbar/AdminToolbar";
import Payment from "./Payment/Payment";
import ProductForm from "./ProductForm/ProductForm";
import ProductItem from "./ProductItem/ProductItem";
import styles from "./receipt.module.css";
import ReceiptDetails from "./ReceiptDetails/ReceiptDetails";
import Toolbar from "./Toolbar/Toolbar";

export default function Receipt() {
  const { t } = useTranslation("components.receipt");
  const params = useParams();
  const [{ data, error, loading }] = useAxios(`receipts/${params?.hash}`);
  const [authToken] = useAuth();
  const [items, setItems] = useState([]);
  const [receipt, setReceipt] = useState([]);
  const [myProducts, setMyProducts] = useState({});
  const [lockedProducts, setLockedProducts] = useState({});
  const [footerStuck, setFooterStuck] = useState(false);
  const [editDetails, setEditDetails] = useState(false);
  const [editProduct, setEditProduct] = useState(false);
  const paymentRef = useRef();

  const mappedItems = items?.map((item) => ({
    ...item,
    lockedAmount: lockedProducts[item?.id] || 0,
    myAmount: myProducts[item?.id] || 0,
  }));

  const selectedProducts = mappedItems?.filter((item) => item?.myAmount > 0);

  const remainingItems = items?.filter(
    (item) => item?.paidAmount < item?.amount
  );

  const footerRef = useCallback((node) => {
    const callback = ([e]) => setFooterStuck(e.intersectionRatio < 1);
    const observer = new IntersectionObserver(callback, { threshold: [1] });
    if (node !== null) {
      observer.observe(node);
    }
  }, []);

  const onItemPicked = (item, v = 1) => {
    if (!receipt?.hostPhone) {
      paymentRef?.current?.highlight();
    } else {
      const items = updateIn(myProducts, [item?.id], (old) => (old || 0) + v);
      setMyProducts(items);
    }
  };

  const onItemRemoved = (item, v = 1) => {
    const items = updateIn(myProducts, [item?.id], (old) => old - v);
    setMyProducts(items);
  };

  const onItemAdded = (item) => {
    if (item) {
      setItems([...items, item]);
    }
    setEditProduct(false);
  };

  const onItemDeleted = (item) => {
    const index = items?.findIndex((obj) => obj?.id === item?.id);
    if (index >= 0) {
      const newItems = removeAt(items, index);
      setItems(newItems);
    }
    setEditProduct(false);
  };

  const onItemUpdated = (item) => {
    const index = items?.findIndex((obj) => obj?.id === item?.id);
    if (index >= 0) {
      const newItems = updateIn(items, [index], (old) => ({ ...old, ...item }));
      setItems(newItems);
    }
    setEditProduct(false);
  };

  const onReceiptUpdated = (newReceipt) => {
    setReceipt(newReceipt);
    setEditDetails(false);
  };

  useEffect(() => {
    setItems(data?.products);
    setReceipt(data?.receipt);
  }, [data]);

  useEffect(() => {
    socket.emit("join", params?.hash);

    // Refresh locked items from other clients.
    socket.on("locked products updated", (products) => {
      setLockedProducts(products);
    });

    // Other clients have paid for products so we need to update paid amount.
    socket.on("paid amount updated", (products) => {
      const newItems = items?.map((item) =>
        set(item, "paidAmount", item.paidAmount + (products[item?.id] || 0))
      );
      setItems(newItems);
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("locked products updated");
      socket.off("paid amount updated");
    };
  }, [items, params?.hash]);

  useEffect(() => {
    socket.emit("my products updated", myProducts);
  }, [myProducts]);

  // Note that we do not show empty message for admin so he can add products.
  if (loading || error || (items?.length === 0 && !authToken)) {
    return (
      <div className={styles.info}>
        {error ? t("error") : loading ? t("loading") : t("empty")}
      </div>
    );
  }

  return (
    <div>
      <div className={styles.header}>
        <Toolbar
          currency={receipt?.currency}
          items={mappedItems}
          onOpenDetails={() => setEditDetails(true)}
          tip={receipt?.tip}
        />
      </div>
      {mappedItems?.length > 0 ? (
        mappedItems?.map((item) => (
          <ProductItem
            amount={item?.amount}
            currency={receipt?.currency}
            icon={item?.icon}
            key={item?.id}
            lockedAmount={item?.lockedAmount}
            name={item?.name}
            onEdit={(warning) => setEditProduct({ item, warning })}
            onItemPicked={(value) => onItemPicked(item, value)}
            paidAmount={item?.paidAmount}
            price={item?.price}
          />
        ))
      ) : (
        <div className={styles.emptyFiltered}>{t("empty")}</div>
      )}
      {authToken && (
        <>
          <AdminToolbar
            hash={receipt?.hash}
            hostPhone={receipt?.hostPhone}
            onAddProduct={() => setEditProduct(true)}
            onOpenDetails={() => setEditDetails(true)}
            onSwapPriceAmount={setItems}
          />
          <Modal onClose={() => setEditDetails(false)} open={editDetails}>
            <ReceiptDetails
              currency={receipt?.currency?.toString()}
              hostPhone={receipt?.hostPhone?.toString()}
              onSuccess={onReceiptUpdated}
              tip={receipt?.tip?.toString()}
            />
          </Modal>
          <Modal onClose={() => setEditProduct(null)} open={!!editProduct}>
            <ProductForm
              item={editProduct?.item}
              onAdd={onItemAdded}
              onDelete={onItemDeleted}
              onUpdate={onItemUpdated}
              warning={editProduct?.warning}
            />
          </Modal>
        </>
      )}
      <div
        className={classNames([
          styles.footer,
          footerStuck && styles.footerStuck,
        ])}
        ref={footerRef}
      >
        <Payment
          currency={receipt?.currency}
          hostPhone={receipt?.hostPhone}
          isPaid={remainingItems?.length === 0}
          onItemRemoved={onItemRemoved}
          ref={paymentRef}
          selectedProducts={selectedProducts}
          tip={receipt?.tip}
          total={receipt?.total}
        />
      </div>
    </div>
  );
}
