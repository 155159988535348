import { useMemo } from "react";

export default function useMobile() {
  const isMobile = useMemo(
    () =>
      navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i),
    []
  );

  return { isMobile };
}
