import "moment/locale/sv";
import "./styles/global.css";
import "./styles/vars.css";

import moment from "moment";
import { createRoot } from "react-dom/client";
import ReactModal from "react-modal";

import Root from "./components/Root/Root";
import * as serviceWorker from "./serviceWorker";

moment.locale("sv");

const elem = document.getElementById("root");

ReactModal.setAppElement(elem);

const root = createRoot(elem);
root.render(<Root />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
