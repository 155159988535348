import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Button, Input, Modal } from "../../../shared";
import styles from "./addName.module.css";

export default function AddName({ id, onSuccess }) {
  const { t } = useTranslation("components.payments", { keyPrefix: "addName" });
  const params = useParams();
  const [name, setName] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async () => {
    setSubmitting(true);
    try {
      const response = await axios.patch(
        `receipts/${params?.hash}/payments/${id}`,
        { guestName: name }
      );
      onSuccess(response?.data);
    } catch {
      toast.error(t("error"));
    }
    setSubmitting(false);
  };

  return (
    <>
      <Button onClick={() => setOpenModal(true)}>{t("add")}</Button>
      <Modal onClose={() => setOpenModal(false)} open={openModal}>
        <div className={styles.content}>
          <Input
            className={styles.name}
            label={t("label")}
            onChange={setName}
            value={name}
          />
          <Button
            className={styles.submit}
            label={t("submit")}
            loading={submitting}
            onClick={onSubmit}
          ></Button>
        </div>
      </Modal>
    </>
  );
}
