import { useRef } from "react";

import { Button } from "../../shared";
import styles from "./uploadButton.module.css";

export default function UploadButton({ capture, icon, label, onUpload }) {
  const fileRef = useRef();

  const onClick = () => {
    fileRef?.current?.click();
  };

  const onChange = () => {
    onUpload(fileRef?.current?.files[0]);
  };

  return (
    <>
      <input
        accept="image/*"
        capture={capture}
        className={styles.fileInput}
        onChange={onChange}
        ref={fileRef}
        type="file"
      />
      <Button
        className={styles.button}
        icon={icon}
        label={label}
        onClick={onClick}
      />
    </>
  );
}
