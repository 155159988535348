import { useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./progressBar.module.css";

export default function ProgressBar() {
  const { t } = useTranslation("components.createReceipt");
  const [progress, setProgress] = useState(0);

  useState(() => {
    // Initially show 20% to indicate upload has began.
    const timeout = setTimeout(() => setProgress(20), 100);

    // Then randomize the rest until 100%.
    const interval = setInterval(() => {
      setProgress((progress) => {
        const newProgress = progress + (Math.random() * (100 - progress)) / 4;
        if (newProgress > 100) {
          clearInterval(interval);
        }
        return newProgress;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <div className={styles.title}>{t("progress.title")}</div>
      <div className={styles.outerBar}>
        <div className={styles.innerBar} style={{ width: `${progress}%` }} />
      </div>
    </>
  );
}
