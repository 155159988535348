import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IoAlertCircleOutline } from "react-icons/io5";

import useAuth from "../../../../hooks/auth";
import useMobile from "../../../../hooks/mobile";
import { formatCurrency, formatQuantity } from "../../../../utils/string";
import styles from "./productDetails.module.css";

export default function ProductDetails({
  amount,
  currency,
  name,
  onEdit,
  price,
}) {
  const { t } = useTranslation("components.receipt", { keyPrefix: "product" });
  const [authToken] = useAuth();
  const { isMobile } = useMobile();

  const warning = useMemo(() => {
    const items = [];
    if (amount <= 0) {
      items.push(t("warning.amount"));
    }
    if (price <= 0) {
      items.push(t("warning.price"));
    }
    if (!name) {
      items.push(t("warning.name"));
    }
    if (items.length > 0) {
      return t("warning.info", { info: items.join(", ") });
    }
    return null;
  }, [amount, name, price, t]);

  const onClick = useCallback(
    (event) => {
      event.stopPropagation();
      onEdit && onEdit(warning);
    },
    [onEdit, warning]
  );

  const renderContent = () => (
    <div>
      <div className={styles.name}>{name || <i>{t("unknown")}</i>}</div>
      <div className={styles.details}>
        {formatCurrency(price, currency)}
        <div className={styles.separator} />
        {formatQuantity(amount)}
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      {authToken ? (
        <>
          <div className={styles.warningContainer} onClick={onClick}>
            {warning && (
              <IoAlertCircleOutline
                className={styles.alertIcon}
                data-tooltip-content={!isMobile ? warning : null}
                data-tooltip-id="my-tooltip"
              />
            )}
            {renderContent()}
          </div>
        </>
      ) : (
        renderContent()
      )}
    </div>
  );
}
