import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Button } from "../../../shared";

export default function RemoveProduct({ id, onSuccess }) {
  const { t } = useTranslation("components.receipt", {
    keyPrefix: "productForm.remove",
  });
  const params = useParams();
  const [submitting, setSubmitting] = useState(false);

  const onClick = async () => {
    setSubmitting(true);
    try {
      const response = await axios.delete(
        `receipts/${params?.hash}/products/${id}`
      );
      onSuccess && onSuccess(response?.data);
    } catch {
      toast.error(t("error"));
    }
    setSubmitting(false);
  };

  return (
    <Button
      color="red"
      label={t("label")}
      loading={submitting}
      onClick={onClick}
    />
  );
}
