import { useTranslation } from "react-i18next";

import styles from "./header.module.css";

export default function Header() {
  const { t } = useTranslation("components.payments");
  return (
    <div className={styles.container}>
      <div className={styles.name}>{t("header.name")}</div>
      <div className={styles.total}>{t("header.total")}</div>
      <div className={styles.products}>{t("header.products")}</div>
    </div>
  );
}
