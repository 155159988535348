import getSymbolFromCurrency from "currency-symbol-map";
import i18next from "i18next";

export const formatCurrency = (total, currency) => {
  const decimals = total % 1 === 0 ? 0 : 2;
  const value = total?.toFixed(decimals);
  const symbol = getSymbolFromCurrency(currency);

  if (!currency) {
    return value;
  } else if (!symbol) {
    return `${currency} ${value}`;
  } else if (symbol?.length === 1) {
    return `${symbol}${value}`;
  } else {
    return `${value} ${symbol}`;
  }
};

export const formatQuantity = (amount) => {
  // Round to maximum two decimals - if necessary.
  const num = Math.round(amount * 100) / 100;
  if (amount === 1) {
    return i18next.t("quantity.singular", { amount: num, ns: "utils.string" });
  }
  return i18next.t("quantity.plural", { amount: num, ns: "utils.string" });
};
