import styles from "./summary.module.css";

export default function Summary({ locked, paid, total }) {
  const lockedPercent = ((paid + locked) / total) * 100;
  const paidPercent = (paid / total) * 100;

  return (
    <div className={styles.container}>
      <div className={styles.progress}>
        <div className={styles.paidBar} style={{ width: `${paidPercent}%` }} />
        <div
          className={styles.lockedBar}
          style={{ width: `${lockedPercent}%` }}
        />
      </div>
    </div>
  );
}
