import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";
import "./axios";
import "./i18n";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Tooltip } from "react-tooltip";

import ConfirmPayment from "../ConfirmPayment/ConfirmPayment";
import { CreateReceipt } from "../CreateReceipt/CreateReceipt";
import MainLayout from "../MainLayout/MainLayout";
import NotFound from "../NotFound/NotFound";
import Payments from "../Payments/Payments";
import Receipt from "../Receipt/Receipt";
import ReceiptLayout from "../ReceiptLayout/ReceiptLayout";

export default function Root() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<MainLayout />} path="r">
            <Route element={<ReceiptLayout />} path=":hash">
              <Route element={<Receipt />} path="" />
              <Route element={<Payments />} path="payments" />
            </Route>
          </Route>
          <Route element={<MainLayout fullscreen />}>
            <Route element={<ConfirmPayment />} path="confirm-payment/:hash" />
            <Route element={<CreateReceipt />} path="" />
            <Route element={<NotFound />} path="*" />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer />
      <Tooltip id="my-tooltip" />
    </>
  );
}
