import classNames from "classnames";
import { useTranslation } from "react-i18next";

import i18n from "../../../Root/i18n";
import styles from "./languages.module.css";

export default function Footer() {
  const { t } = useTranslation("components.mainLayout");

  const items = [
    { code: "sv", label: "Svenska" },
    { code: "en", label: "English" },
  ];

  const onChange = (item) => {
    i18n.changeLanguage(item?.code);
  };

  return (
    <div className={styles.container}>
      <div className={styles.selectLabel}>{t("selectLanguage")}:</div>
      {items?.map((item) => (
        <div
          className={classNames([
            styles.item,
            i18n.language === item?.code && styles.selected,
          ])}
          key={item?.code}
          onClick={() => onChange(item)}
        >
          {item?.label}
        </div>
      ))}
    </div>
  );
}
